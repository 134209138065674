.rightElement {
  /* background-image: url('../assets/images/rightElement.png');  */
  background-image: url("../assets/images/rightElement.svg");
  background-position: right;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 95% 95%;
}

.mobileAuthLogo {
  /* background-image: url('../assets/images/rightElement.png');  */
  background-image: url("../assets/images/logoMobile.svg");
  background-position: center;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 70% 70%;
}
