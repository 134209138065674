.activeIcon svg {
  stroke: #ffffff;
  display: flex;
  align-items: center;
}

.active {
  background-color: #142953 !important;
  color: #fff !important;
  display: flex;
  align-items: center;
}

.active.stroke svg {
  stroke: #fff !important;
  /* stroke-width: 1%; */
  display: flex;
  align-items: center;
}

.active svg {
  stroke: #ffffff;
  display: flex;
  align-items: center;
}

.inActiveIcon svg {
  color: #142953;
  background-color: #ffffff;
  display: flex;
  align-items: center !important;
}
